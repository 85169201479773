export const industryGrossMarginLookup = {
    1: { name: "Advertising", margin: 29.17 },
    2: { name: "Aerospace/Defense", margin: 16.69 },
    3: { name: "Air Transport", margin: 21.20 },
    4: { name: "Apparel",  margin: 51.84},
    5: { name: "Auto & Truck",  margin: 14.70},
    6: { name: "Beverage (Soft)",  margin: 53.55},
    7: { name: "Electronics (General)",  margin: 27.35},
    8: { name: "Healthcare Products",  margin: 57.74},
    9: { name: "Shoe",  margin: 45.35},
};

export const blobData = "Industry Name 	Number of firms 	Gross Margin 	Net Margin 	Pre-tax, Pre-stock compensation Operating Margin 	Pre-tax Unadjusted Operating Margin 	After-tax Unadjusted Operating Margin 	Pre-tax Lease adjusted Margin 	After-tax Lease Adjusted Margin 	Pre-tax Lease & R&D adj Margin 	After-tax Lease & R&D adj Margin 	EBITDA/Sales 	EBITDASG&A/Sales 	EBITDAR&D/Sales 	COGS/Sales 	R&D/Sales 	SG&A/ Sales 	Stock-Based Compensation/Sales 	Lease Expense/Sales"
+"Advertising 	58 	29.17% 	3.79% 	13.50% 	10.62% 	9.94% 	11.11% 	10.40% 	11.14% 	10.43% 	14.07% 	27.50% 	15.80% 	70.83% 	1.73% 	13.43% 	2.88% 	3.53%"
+"Aerospace/Defense 	77 	16.69% 	4.05% 	9.89% 	8.64% 	7.89% 	8.68% 	7.93% 	8.92% 	8.17% 	13.64% 	19.55% 	18.41% 	83.31% 	4.76% 	5.91% 	1.26% 	0.81%"
+"Air Transport 	21 	21.20% 	-1.71% 	2.55% 	2.11% 	1.89% 	2.08% 	1.87% 	2.14% 	1.92% 	-3.31% 	0.16% 	-2.97% 	78.80% 	0.34% 	3.47% 	0.44% 	7.85%"
+"Apparel 	39 	51.84% 	5.07% 	11.06% 	10.16% 	8.94% 	11.11% 	9.77% 	11.11% 	9.78% 	14.19% 	55.64% 	14.31% 	48.16% 	0.13% 	41.46% 	0.90% 	5.07%"
+"Auto & Truck 	31 	14.70% 	5.02% 	7.95% 	6.43% 	6.24% 	6.49% 	6.30% 	7.03% 	6.84% 	9.64% 	15.84% 	15.39% 	85.30% 	5.75% 	6.20% 	1.52% 	0.39%"
+"Auto Parts 	37 	14.56% 	2.16% 	5.82% 	5.06% 	4.59% 	5.19% 	4.71% 	5.68% 	5.20% 	9.27% 	17.11% 	13.45% 	85.44% 	4.18% 	7.84% 	0.75% 	0.76%"
+"Bank (Money Center) 	7 	100.00% 	26.96% 	2.38% 	0.00% 	0.00% 	0.10% 	0.08% 	0.10% 	0.08% 	0.00% 	57.98% 	0.00% 	0.00% 	0.00% 	57.98% 	2.38% 	2.53%"
+"Banks (Regional) 	557 	99.79% 	30.31% 	1.45% 	0.00% 	0.00% 	-0.10% 	-0.08% 	-0.10% 	-0.08% 	0.00% 	50.27% 	0.00% 	0.21% 	0.00% 	50.27% 	1.45% 	2.47%"
+"Beverage (Alcoholic) 	23 	44.42% 	5.76% 	20.59% 	20.06% 	18.18% 	20.17% 	18.28% 	20.17% 	18.28% 	26.06% 	49.65% 	26.06% 	55.58% 	0.00% 	23.59% 	0.52% 	1.57%"
+"Beverage (Soft) 	31 	53.55% 	14.60% 	19.66% 	19.04% 	17.82% 	19.14% 	17.91% 	19.14% 	17.92% 	21.71% 	55.62% 	22.23% 	46.45% 	0.52% 	33.91% 	0.63% 	1.13%"
+"Broadcasting 	26 	40.03% 	11.90% 	15.36% 	14.58% 	12.28% 	14.75% 	12.42% 	14.75% 	12.42% 	19.20% 	40.50% 	19.21% 	59.97% 	0.00% 	21.30% 	0.78% 	1.00%"
+"Brokerage & Investment Banking 	30 	61.81% 	16.01% 	4.31% 	0.41% 	0.35% 	0.31% 	0.27% 	0.26% 	0.21% 	0.43% 	35.34% 	0.87% 	38.19% 	0.44% 	34.91% 	3.90% 	2.18%"
+"Building Materials 	45 	29.45% 	10.30% 	14.41% 	13.81% 	11.50% 	13.94% 	11.61% 	14.00% 	11.67% 	13.59% 	28.33% 	14.53% 	70.55% 	0.94% 	14.74% 	0.60% 	0.86%"
+"Business & Consumer Services 	164 	31.20% 	4.92% 	10.29% 	9.03% 	8.18% 	9.22% 	8.35% 	9.42% 	8.55% 	12.58% 	30.73% 	13.63% 	68.80% 	1.05% 	18.15% 	1.26% 	1.74%"
+"Cable TV 	10 	58.62% 	7.91% 	20.99% 	19.90% 	15.53% 	19.52% 	15.23% 	19.53% 	15.24% 	31.28% 	56.46% 	31.42% 	41.38% 	0.14% 	25.19% 	1.09% 	0.81%"
+"Chemical (Basic) 	38 	17.85% 	9.70% 	13.47% 	13.05% 	11.77% 	13.14% 	11.85% 	13.15% 	11.86% 	17.86% 	21.61% 	18.61% 	82.15% 	0.75% 	3.75% 	0.41% 	1.41%"
+"Chemical (Diversified) 	4 	23.97% 	13.16% 	13.95% 	13.52% 	11.89% 	13.56% 	11.93% 	13.63% 	12.00% 	13.15% 	22.21% 	14.69% 	76.03% 	1.53% 	9.06% 	0.43% 	1.04%"
+"Chemical (Specialty) 	76 	34.23% 	8.07% 	16.90% 	14.62% 	13.05% 	14.80% 	13.21% 	15.30% 	13.71% 	17.83% 	33.06% 	20.86% 	65.77% 	3.03% 	15.23% 	2.28% 	1.24%"
+"Coal & Related Energy 	19 	35.75% 	20.44% 	23.50% 	22.15% 	21.65% 	22.17% 	21.67% 	22.33% 	21.83% 	12.64% 	17.21% 	13.00% 	64.25% 	0.36% 	4.56% 	1.34% 	0.81%"
+"Computer Services 	80 	24.23% 	2.53% 	7.45% 	6.57% 	6.15% 	6.89% 	6.44% 	6.95% 	6.51% 	9.21% 	23.79% 	11.58% 	75.77% 	2.36% 	14.57% 	0.87% 	1.16%"
+"Computers/Peripherals 	42 	36.39% 	16.68% 	23.12% 	21.41% 	19.45% 	21.43% 	19.47% 	22.03% 	20.07% 	24.46% 	33.48% 	30.36% 	63.61% 	5.91% 	9.02% 	1.71% 	2.85%"
+"Construction Supplies 	49 	21.82% 	8.23% 	11.82% 	11.13% 	9.96% 	11.16% 	9.99% 	11.44% 	10.27% 	13.55% 	21.32% 	16.29% 	78.18% 	2.74% 	7.77% 	0.69% 	0.60%"
+"Diversified 	23 	10.16% 	0.98% 	3.63% 	3.44% 	3.34% 	3.59% 	3.48% 	3.62% 	3.52% 	32.42% 	38.09% 	33.70% 	89.84% 	1.28% 	5.67% 	0.19% 	0.83%"
+"Drugs (Biotechnology) 	598 	60.94% 	0.65% 	18.33% 	12.02% 	11.91% 	11.87% 	11.76% 	14.26% 	14.15% 	22.57% 	50.85% 	61.53% 	39.06% 	38.96% 	28.27% 	6.31% 	1.62%"
+"Drugs (Pharmaceutical) 	281 	67.02% 	18.35% 	28.87% 	27.39% 	26.74% 	27.37% 	26.72% 	26.63% 	25.98% 	31.94% 	52.34% 	49.78% 	32.98% 	17.84% 	20.40% 	1.48% 	0.72%"
+"Education 	33 	46.61% 	2.92% 	8.92% 	5.28% 	4.90% 	5.16% 	4.79% 	5.83% 	5.46% 	10.65% 	45.96% 	15.35% 	53.39% 	4.70% 	35.32% 	3.65% 	3.53%"
+"Electrical Equipment 	110 	32.33% 	7.31% 	11.61% 	9.97% 	9.52% 	10.25% 	9.79% 	10.77% 	10.31% 	14.30% 	33.63% 	17.81% 	67.67% 	3.52% 	19.34% 	1.64% 	0.98%"
+"Electronics (Consumer & Office) 	16 	32.29% 	0.54% 	5.17% 	1.85% 	1.78% 	2.11% 	2.03% 	2.54% 	2.46% 	4.47% 	26.96% 	12.16% 	67.71% 	7.69% 	22.49% 	3.32% 	0.73%"
+"Electronics (General) 	138 	27.35% 	6.32% 	11.11% 	9.74% 	9.12% 	9.83% 	9.21% 	10.17% 	9.55% 	13.79% 	26.09% 	18.87% 	72.65% 	5.08% 	12.30% 	1.38% 	0.82%"
+"Engineering/Construction 	43 	13.92% 	2.16% 	4.87% 	4.42% 	3.83% 	4.69% 	4.07% 	4.69% 	4.07% 	7.07% 	14.90% 	7.10% 	86.08% 	0.03% 	7.83% 	0.45% 	2.54%"
+"Entertainment 	110 	40.44% 	0.90% 	9.91% 	7.51% 	7.25% 	7.44% 	7.19% 	7.93% 	7.67% 	14.49% 	36.66% 	18.47% 	59.56% 	3.98% 	22.17% 	2.40% 	1.96%"
+"Environmental & Waste Services 	62 	32.74% 	7.29% 	13.25% 	12.59% 	11.91% 	12.85% 	12.15% 	12.85% 	12.15% 	18.50% 	31.43% 	18.52% 	67.26% 	0.01% 	12.92% 	0.65% 	1.78%"
+"Farming/Agriculture 	39 	13.23% 	5.66% 	7.84% 	7.62% 	7.12% 	7.78% 	7.27% 	7.91% 	7.39% 	8.33% 	12.82% 	9.45% 	86.77% 	1.12% 	4.49% 	0.22% 	1.01%"
+"Financial Svcs. (Non-bank & Insurance) 	223 	75.85% 	26.32% 	17.99% 	15.89% 	13.57% 	15.88% 	13.56% 	16.18% 	13.86% 	15.32% 	45.71% 	16.40% 	24.15% 	1.08% 	30.39% 	2.10% 	0.83%"
+"Food Processing 	92 	24.63% 	7.10% 	12.24% 	11.77% 	10.86% 	11.94% 	11.01% 	11.96% 	11.03% 	14.83% 	27.14% 	15.45% 	75.37% 	0.61% 	12.31% 	0.47% 	1.80%"
+"Food Wholesalers 	14 	14.39% 	1.09% 	2.24% 	2.10% 	1.85% 	2.10% 	1.85% 	2.10% 	1.85% 	2.93% 	14.93% 	2.93% 	85.61% 	0.00% 	12.00% 	0.14% 	0.28%"
+"Furn/Home Furnishings 	32 	26.38% 	2.03% 	8.53% 	7.66% 	6.69% 	7.89% 	6.89% 	7.96% 	6.96% 	12.99% 	30.82% 	14.55% 	73.62% 	1.56% 	17.83% 	0.87% 	1.60%"
+"Green & Renewable Energy 	19 	62.86% 	17.77% 	26.97% 	26.10% 	24.34% 	24.48% 	22.83% 	24.44% 	22.79% 	53.16% 	62.81% 	53.28% 	37.14% 	0.12% 	9.65% 	0.87% 	0.86%"
+"Healthcare Products 	254 	57.74% 	7.00% 	17.41% 	15.02% 	14.46% 	15.13% 	14.57% 	15.92% 	15.36% 	22.42% 	54.68% 	30.77% 	42.26% 	8.35% 	32.26% 	2.40% 	0.92%"
+"Healthcare Support Services 	131 	14.72% 	2.01% 	4.35% 	4.07% 	3.80% 	4.00% 	3.73% 	4.01% 	3.74% 	4.74% 	14.41% 	4.83% 	85.28% 	0.08% 	9.66% 	0.28% 	0.44%"
+"Heathcare Information and Technology 	138 	49.89% 	-0.33% 	19.37% 	16.93% 	16.20% 	17.00% 	16.27% 	17.59% 	16.86% 	24.00% 	48.62% 	30.47% 	50.11% 	6.47% 	24.62% 	2.44% 	1.10%"
+"Homebuilding 	32 	27.32% 	13.98% 	19.07% 	18.76% 	15.42% 	18.79% 	15.45% 	18.79% 	15.45% 	17.18% 	25.70% 	17.18% 	72.68% 	0.00% 	8.51% 	0.31% 	0.23%"
+"Hospitals/Healthcare Facilities 	34 	35.63% 	5.31% 	12.24% 	11.62% 	10.51% 	11.62% 	10.51% 	11.62% 	10.51% 	17.21% 	20.52% 	17.21% 	64.37% 	0.00% 	3.31% 	0.62% 	2.48%"
+"Hotel/Gaming 	69 	56.29% 	1.10% 	10.00% 	7.25% 	6.66% 	4.23% 	3.89% 	3.95% 	3.61% 	9.27% 	42.12% 	11.59% 	43.71% 	2.33% 	32.85% 	2.75% 	2.55%"
+"Household Products 	127 	47.59% 	11.25% 	17.86% 	16.99% 	15.85% 	17.12% 	15.97% 	17.13% 	15.98% 	21.36% 	51.42% 	23.06% 	52.41% 	1.70% 	30.06% 	0.87% 	1.31%"
+"Information Services 	73 	55.75% 	16.62% 	26.88% 	24.02% 	21.03% 	24.21% 	21.20% 	24.73% 	21.72% 	29.35% 	55.11% 	33.15% 	44.25% 	3.81% 	25.76% 	2.86% 	1.10%"
+"Insurance (General) 	21 	40.00% 	15.21% 	22.83% 	21.82% 	19.58% 	21.86% 	19.61% 	21.86% 	19.62% 	22.07% 	35.33% 	22.15% 	60.00% 	0.08% 	13.26% 	1.01% 	0.85%"
+"Insurance (Life) 	27 	25.99% 	6.07% 	8.81% 	8.34% 	7.39% 	8.39% 	7.43% 	8.39% 	7.43% 	14.29% 	25.63% 	14.29% 	74.01% 	0.00% 	11.33% 	0.47% 	0.32%"
+"Insurance (Prop/Cas.) 	51 	24.27% 	4.05% 	6.79% 	6.41% 	5.71% 	6.49% 	5.78% 	6.49% 	5.78% 	16.88% 	28.26% 	16.88% 	75.73% 	0.00% 	11.38% 	0.38% 	0.45%"
+"Investments & Asset Management 	600 	65.08% 	24.93% 	22.43% 	18.36% 	17.63% 	18.15% 	17.42% 	18.19% 	17.46% 	25.36% 	57.06% 	25.96% 	34.92% 	0.60% 	31.70% 	4.07% 	1.25%"
+"Machinery 	116 	34.20% 	8.51% 	14.80% 	13.80% 	12.37% 	14.00% 	12.55% 	14.15% 	12.70% 	17.21% 	35.67% 	19.32% 	65.80% 	2.11% 	18.46% 	1.00% 	0.88%"
+"Metals & Mining 	68 	32.76% 	9.66% 	23.44% 	22.89% 	21.94% 	22.84% 	21.90% 	22.86% 	21.91% 	32.32% 	35.62% 	32.51% 	67.24% 	0.19% 	3.30% 	0.55% 	0.66%"
+"Office Equipment & Services 	16 	32.45% 	2.36% 	6.63% 	5.94% 	4.78% 	6.26% 	5.03% 	6.34% 	5.12% 	8.90% 	33.58% 	10.80% 	67.55% 	1.90% 	24.68% 	0.68% 	1.92%"
+"Oil/Gas (Integrated) 	4 	36.54% 	15.17% 	17.58% 	17.32% 	14.86% 	17.46% 	14.98% 	17.44% 	14.96% 	14.21% 	20.50% 	14.38% 	63.46% 	0.17% 	6.29% 	0.26% 	0.54%"
+"Oil/Gas (Production and Exploration) 	174 	64.45% 	26.01% 	36.20% 	35.55% 	33.91% 	35.68% 	34.04% 	35.68% 	34.04% 	24.73% 	28.55% 	24.75% 	35.55% 	0.03% 	3.83% 	0.66% 	1.37%"
+"Oil/Gas Distribution 	23 	23.60% 	2.08% 	11.02% 	10.55% 	9.83% 	10.82% 	10.07% 	10.82% 	10.08% 	17.17% 	21.19% 	17.17% 	76.40% 	0.00% 	4.02% 	0.46% 	0.80%"
+"Oilfield Svcs/Equip. 	101 	11.83% 	5.25% 	7.50% 	7.26% 	6.75% 	7.37% 	6.85% 	7.37% 	6.85% 	3.63% 	6.50% 	3.84% 	88.17% 	0.21% 	2.87% 	0.24% 	0.77%"
+"Packaging & Container 	25 	21.33% 	6.06% 	9.88% 	9.43% 	8.05% 	9.63% 	8.22% 	9.63% 	8.22% 	12.99% 	22.57% 	13.40% 	78.67% 	0.42% 	9.58% 	0.44% 	0.96%"
+"Paper/Forest Products 	7 	29.64% 	10.23% 	18.93% 	18.47% 	16.12% 	18.59% 	16.21% 	18.59% 	16.22% 	22.81% 	32.71% 	22.83% 	70.36% 	0.02% 	9.90% 	0.46% 	0.60%"
+"Power 	48 	35.40% 	9.17% 	15.92% 	15.57% 	13.66% 	15.67% 	13.74% 	15.68% 	13.76% 	27.84% 	29.69% 	27.93% 	64.60% 	0.09% 	1.85% 	0.34% 	1.64%"
+"Precious Metals 	74 	36.98% 	7.18% 	11.15% 	10.03% 	9.74% 	10.10% 	9.81% 	10.50% 	10.21% 	38.71% 	45.94% 	40.03% 	63.02% 	1.32% 	7.23% 	1.12% 	2.85%"
+"Publishing & Newspapers 	20 	46.55% 	2.82% 	8.43% 	7.73% 	6.98% 	7.75% 	7.00% 	7.84% 	7.10% 	13.09% 	44.91% 	14.14% 	53.45% 	1.05% 	31.82% 	0.70% 	1.55%"
+"R.E.I.T. 	223 	60.46% 	23.77% 	27.23% 	25.46% 	24.60% 	23.20% 	22.42% 	23.20% 	22.42% 	43.04% 	53.73% 	43.05% 	39.54% 	0.01% 	10.69% 	1.77% 	2.77%"
+"Real Estate (Development) 	18 	32.51% 	15.04% 	19.38% 	18.64% 	17.40% 	17.48% 	16.32% 	17.48% 	16.32% 	17.70% 	24.53% 	17.70% 	67.49% 	0.00% 	6.84% 	0.73% 	0.39%"
+"Real Estate (General/Diversified) 	12 	48.08% 	12.67% 	20.93% 	18.57% 	16.83% 	18.60% 	16.86% 	18.60% 	16.86% 	25.29% 	58.46% 	25.29% 	51.92% 	0.00% 	33.17% 	2.36% 	3.03%"
+"Real Estate (Operations & Services) 	60 	31.13% 	-0.76% 	2.97% 	1.17% 	1.10% 	0.50% 	0.47% 	0.71% 	0.69% 	2.94% 	29.00% 	4.20% 	68.87% 	1.27% 	26.06% 	1.80% 	3.66%"
+"Recreation 	57 	36.95% 	1.30% 	11.79% 	9.19% 	8.32% 	8.31% 	7.52% 	8.52% 	7.73% 	13.05% 	36.37% 	15.90% 	63.05% 	2.85% 	23.31% 	2.60% 	2.08%"
+"Reinsurance 	1 	10.64% 	3.54% 	4.93% 	4.65% 	4.35% 	4.64% 	4.34% 	4.64% 	4.34% 	5.86% 	6.19% 	5.86% 	89.36% 	0.00% 	0.34% 	0.28% 	0.00%"
+"Restaurant/Dining 	70 	30.07% 	9.28% 	16.33% 	15.42% 	14.10% 	12.80% 	11.71% 	12.80% 	11.71% 	19.82% 	29.26% 	19.82% 	69.93% 	0.00% 	9.45% 	0.91% 	5.87%"
+"Retail (Automotive) 	30 	21.04% 	4.07% 	6.48% 	6.19% 	5.21% 	5.73% 	4.83% 	5.74% 	4.83% 	7.31% 	21.44% 	7.32% 	78.96% 	0.01% 	14.13% 	0.29% 	1.18%"
+"Retail (Building Supply) 	15 	34.51% 	8.67% 	14.15% 	13.85% 	11.99% 	13.81% 	11.96% 	13.81% 	11.96% 	15.64% 	34.66% 	15.66% 	65.49% 	0.03% 	19.02% 	0.30% 	1.48%"
+"Retail (Distributors) 	69 	31.30% 	7.30% 	12.03% 	11.66% 	10.08% 	11.90% 	10.28% 	11.91% 	10.30% 	10.47% 	28.61% 	10.51% 	68.70% 	0.04% 	18.14% 	0.36% 	1.24%"
+"Retail (General) 	15 	23.25% 	2.35% 	4.58% 	4.35% 	3.42% 	4.12% 	3.25% 	4.12% 	3.25% 	6.72% 	25.29% 	6.72% 	76.75% 	0.00% 	18.57% 	0.24% 	0.91%"
+"Retail (Grocery and Food) 	13 	24.71% 	1.96% 	3.48% 	3.32% 	2.78% 	2.92% 	2.44% 	2.92% 	2.44% 	5.04% 	24.43% 	5.04% 	75.29% 	0.00% 	19.39% 	0.16% 	0.92%"
+"Retail (Online) 	63 	42.78% 	0.64% 	5.85% 	2.30% 	2.21% 	1.84% 	1.76% 	3.87% 	3.79% 	11.14% 	39.33% 	23.19% 	57.22% 	12.05% 	28.20% 	3.54% 	1.67%"
+"Retail (Special Lines) 	78 	29.90% 	3.86% 	5.90% 	5.43% 	4.61% 	5.74% 	4.87% 	5.74% 	4.88% 	8.75% 	32.77% 	8.83% 	70.10% 	0.09% 	24.02% 	0.47% 	5.03%"
+"Rubber& Tires 	3 	19.96% 	4.21% 	5.71% 	5.55% 	5.55% 	5.84% 	5.84% 	6.04% 	6.04% 	9.17% 	23.57% 	11.59% 	80.04% 	2.42% 	14.40% 	0.16% 	1.50%"
+"Semiconductor 	68 	54.23% 	22.74% 	29.74% 	25.44% 	23.36% 	25.44% 	23.36% 	27.73% 	25.65% 	41.86% 	50.17% 	60.27% 	45.77% 	18.41% 	8.32% 	4.30% 	0.67%"
+"Semiconductor Equip 	30 	44.65% 	22.27% 	28.96% 	27.30% 	24.31% 	27.44% 	24.44% 	28.26% 	25.26% 	29.31% 	37.11% 	38.44% 	55.35% 	9.13% 	7.80% 	1.66% 	0.87%"
+"Shipbuilding & Marine 	8 	36.12% 	21.55% 	26.01% 	25.50% 	23.91% 	26.33% 	24.69% 	26.33% 	24.69% 	23.08% 	29.67% 	23.08% 	63.88% 	0.00% 	6.59% 	0.51% 	2.34%"
+"Shoe 	13 	45.35% 	11.17% 	14.07% 	12.86% 	11.48% 	12.83% 	11.46% 	12.84% 	11.47% 	14.62% 	46.94% 	14.73% 	54.65% 	0.10% 	32.31% 	1.22% 	2.23%"
+"Software (Entertainment) 	91 	63.23% 	20.91% 	33.55% 	25.91% 	24.93% 	25.65% 	24.67% 	28.97% 	27.99% 	33.69% 	53.38% 	51.06% 	36.77% 	17.37% 	19.70% 	7.64% 	1.30%"
+"Software (Internet) 	33 	58.92% 	-19.07% 	11.30% 	-5.85% 	-5.71% 	-5.48% 	-5.35% 	-1.67% 	-1.54% 	4.39% 	47.24% 	23.59% 	41.08% 	19.20% 	42.85% 	17.16% 	2.94%"
+"Software (System & Application) 	390 	70.92% 	14.61% 	30.36% 	21.81% 	21.07% 	21.90% 	21.16% 	24.17% 	23.43% 	28.79% 	59.30% 	46.30% 	29.08% 	17.51% 	30.51% 	8.55% 	1.52%"
+"Steel 	28 	26.23% 	14.70% 	20.15% 	19.84% 	16.87% 	19.89% 	16.92% 	19.89% 	16.92% 	18.74% 	24.19% 	18.77% 	73.77% 	0.03% 	5.44% 	0.32% 	0.35%"
+"Telecom (Wireless) 	16 	57.91% 	2.54% 	20.66% 	14.05% 	13.51% 	12.37% 	11.89% 	12.27% 	11.80% 	31.48% 	57.72% 	31.52% 	42.09% 	0.04% 	26.25% 	6.61% 	7.48%"
+"Telecom. Equipment 	79 	53.85% 	13.29% 	21.81% 	18.34% 	17.60% 	18.63% 	17.88% 	19.08% 	18.33% 	22.88% 	43.80% 	36.18% 	46.15% 	13.30% 	20.92% 	3.47% 	1.35%"
+"Telecom. Services 	49 	55.53% 	12.81% 	20.50% 	19.98% 	18.68% 	19.95% 	18.64% 	20.01% 	18.70% 	35.18% 	56.47% 	35.76% 	44.47% 	0.58% 	21.30% 	0.52% 	3.66%"
+"Tobacco 	15 	62.60% 	23.46% 	44.24% 	43.76% 	39.46% 	43.97% 	39.64% 	44.06% 	39.74% 	46.75% 	65.22% 	48.08% 	37.40% 	1.33% 	18.47% 	0.47% 	0.68%"
+"Transportation 	18 	21.94% 	6.99% 	9.91% 	9.35% 	7.82% 	9.38% 	7.84% 	9.38% 	7.84% 	11.98% 	13.06% 	11.99% 	78.06% 	0.01% 	1.08% 	0.56% 	3.44%"
+"Transportation (Railroads) 	4 	52.26% 	27.65% 	40.58% 	40.06% 	33.43% 	39.86% 	33.25% 	39.86% 	33.25% 	46.42% 	46.47% 	46.42% 	47.74% 	0.00% 	0.05% 	0.52% 	1.46%"
+"Trucking 	35 	27.26% 	1.29% 	10.92% 	8.93% 	7.61% 	9.18% 	7.82% 	9.50% 	8.15% 	9.81% 	21.57% 	12.27% 	72.74% 	2.47% 	11.76% 	1.99% 	6.31%"
+"Utility (General) 	15 	36.67% 	12.68% 	18.53% 	18.19% 	15.79% 	18.03% 	15.65% 	18.01% 	15.63% 	30.01% 	29.22% 	30.03% 	63.33% 	0.02% 	-0.79% 	0.33% 	0.29%"
+"Utility (Water) 	16 	54.31% 	25.12% 	30.11% 	29.51% 	27.02% 	29.38% 	26.90% 	29.37% 	26.89% 	43.87% 	47.07% 	43.87% 	45.69% 	0.00% 	3.20% 	0.60% 	0.23%"
+"Total Market 	7165 	36.28% 	8.89% 	13.13% 	11.66% 	10.78% 	11.60% 	10.73% 	11.93% 	11.05% 	15.68% 	31.63% 	18.96% 	63.72% 	3.28% 	15.95% 	1.47% 	1.49%"
+"Total Market (without financials) 	5649 	33.19% 	7.77% 	13.52% 	12.10% 	11.33% 	12.03% 	11.26% 	12.39% 	11.62% 	16.27% 	30.42% 	19.88% 	66.81% 	3.60% 	14.14% 	1.42% 	1.50%"

export const industryGrossMarginLookupArray = [
        {
            "id": 1,
            "name": "Advertising",
            "numberOfFirms": 58,
            "margin": 29.17,
            "netMargin": 3.79,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 2,
            "name": "Aerospace/Defense",
            "numberOfFirms": 77,
            "margin": 16.69,
            "netMargin": 4.05,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 3,
            "name": "Air Transport",
            "numberOfFirms": 21,
            "margin": 21.20,
            "netMargin": -1.71,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 4,
            "name": "Apparel",
            "numberOfFirms": 39,
            "margin": 51.84,
            "netMargin": 5.07,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 5,
            "name": "Auto & Truck",
            "numberOfFirms": 31,
            "margin": 14.70,
            "netMargin": 5.02,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 6,
            "name": "Auto Parts",
            "numberOfFirms": 37,
            "margin": 14.56,
            "netMargin": 2.16,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 7,
            "name": "Bank (Money Center)",
            "numberOfFirms": 7,
            "margin": 100.00,
            "netMargin": 26.96,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 8,
            "name": "Banks (Regional)",
            "numberOfFirms": 557,
            "margin": 99.79,
            "netMargin": 30.31,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 9,
            "name": "Beverage (Alcoholic)",
            "numberOfFirms": 23,
            "margin": 44.42,
            "netMargin": 5.76,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 10,
            "name": "Beverage (Soft)",
            "numberOfFirms": 31,
            "margin": 53.55,
            "netMargin": 14.60,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 11,
            "name": "Broadcasting",
            "numberOfFirms": 26,
            "margin": 40.03,
            "netMargin": 11.90,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 12,
            "name": "Brokerage & Investment Banking",
            "numberOfFirms": 30,
            "margin": 61.81,
            "netMargin": 16.01,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 13,
            "name": "Building Materials",
            "numberOfFirms": 45,
            "margin": 29.45,
            "netMargin": 10.30,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 14,
            "name": "Business & Consumer Services",
            "numberOfFirms": 164,
            "margin": 31.20,
            "netMargin": 4.92,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 15,
            "name": "Cable TV",
            "numberOfFirms": 10,
            "margin": 58.62,
            "netMargin": 7.91,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 16,
            "name": "Chemical (Basic)",
            "numberOfFirms": 38,
            "margin": 17.85,
            "netMargin": 9.70,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 17,
            "name": "Chemical (Diversified)",
            "numberOfFirms": 4,
            "margin": 23.97,
            "netMargin": 13.16,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 18,
            "name": "Chemical (Specialty)",
            "numberOfFirms": 76,
            "margin": 34.23,
            "netMargin": 8.07,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 19,
            "name": "Coal & Related Energy",
            "numberOfFirms": 19,
            "margin": 35.75,
            "netMargin": 20.44,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 20,
            "name": "Computer Services",
            "numberOfFirms": 80,
            "margin": 24.23,
            "netMargin": 2.53,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 21,
            "name": "Computers/Peripherals",
            "numberOfFirms": 42,
            "margin": 36.39,
            "netMargin": 16.68,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 22,
            "name": "Construction Supplies",
            "numberOfFirms": 49,
            "margin": 21.82,
            "netMargin": 8.23,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 23,
            "name": "Diversified",
            "numberOfFirms": 23,
            "margin": 10.16,
            "netMargin": 0.98,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 24,
            "name": "Drugs (Biotechnology)",
            "numberOfFirms": 598,
            "margin": 60.94,
            "netMargin": 0.65,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 25,
            "name": "Drugs (Pharmaceutical)",
            "numberOfFirms": 281,
            "margin": 67.02,
            "netMargin": 18.35,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 26,
            "name": "Education",
            "numberOfFirms": 33,
            "margin": 46.61,
            "netMargin": 2.92,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 27,
            "name": "Electrical Equipment",
            "numberOfFirms": 110,
            "margin": 32.33,
            "netMargin": 7.31,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 28,
            "name": "Electronics (Consumer & Office)",
            "numberOfFirms": 16,
            "margin": 32.29,
            "netMargin": 0.54,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 29,
            "name": "Electronics (General)",
            "numberOfFirms": 138,
            "margin": 27.35,
            "netMargin": 6.32,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 30,
            "name": "Engineering/Construction",
            "numberOfFirms": 43,
            "margin": 13.92,
            "netMargin": 2.16,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 31,
            "name": "Entertainment",
            "numberOfFirms": 110,
            "margin": 40.44,
            "netMargin": 0.90,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 32,
            "name": "Environmental & Waste Services",
            "numberOfFirms": 62,
            "margin": 32.74,
            "netMargin": 7.29,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 33,
            "name": "Farming/Agriculture",
            "numberOfFirms": 39,
            "margin": 13.23,
            "netMargin": 5.66,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 34,
            "name": "Financial Svcs. (Non-bank & Insurance)",
            "numberOfFirms": 223,
            "margin": 75.85,
            "netMargin": 26.32,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 35,
            "name": "Food Processing",
            "numberOfFirms": 92,
            "margin": 24.63,
            "netMargin": 7.10,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 36,
            "name": "Food Wholesalers",
            "numberOfFirms": 14,
            "margin": 14.39,
            "netMargin": 1.09,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 37,
            "name": "Furn/Home Furnishings",
            "numberOfFirms": 32,
            "margin": 26.38,
            "netMargin": 2.03,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 38,
            "name": "Green & Renewable Energy",
            "numberOfFirms": 19,
            "margin": 62.86,
            "netMargin": 17.77,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 39,
            "name": "Healthcare Products",
            "numberOfFirms": 254,
            "margin": 57.74,
            "netMargin": 7.00,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 40,
            "name": "Healthcare Support Services",
            "numberOfFirms": 131,
            "margin": 14.72,
            "netMargin": 2.01,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 41,
            "name": "Heathcare Information and Technology",
            "numberOfFirms": 138,
            "margin": 49.89,
            "netMargin": -0.33,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 42,
            "name": "Homebuilding",
            "numberOfFirms": 32,
            "margin": 27.32,
            "netMargin": 13.98,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 43,
            "name": "Hospitals/Healthcare Facilities",
            "numberOfFirms": 34,
            "margin": 35.63,
            "netMargin": 5.31,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 44,
            "name": "Hotel/Gaming",
            "numberOfFirms": 69,
            "margin": 56.29,
            "netMargin": 1.10,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 45,
            "name": "Household Products",
            "numberOfFirms": 127,
            "margin": 47.59,
            "netMargin": 11.25,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 46,
            "name": "Information Services",
            "numberOfFirms": 73,
            "margin": 55.75,
            "netMargin": 16.62,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 47,
            "name": "Insurance (General)",
            "numberOfFirms": 21,
            "margin": 40.00,
            "netMargin": 15.21,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 48,
            "name": "Insurance (Life)",
            "numberOfFirms": 27,
            "margin": 25.99,
            "netMargin": 6.07,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 49,
            "name": "Insurance (Prop/Cas.)",
            "numberOfFirms": 51,
            "margin": 24.27,
            "netMargin": 4.05,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 50,
            "name": "Investments & Asset Management",
            "numberOfFirms": 600,
            "margin": 65.08,
            "netMargin": 24.93,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 51,
            "name": "Machinery",
            "numberOfFirms": 116,
            "margin": 34.20,
            "netMargin": 8.51,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 52,
            "name": "Metals & Mining",
            "numberOfFirms": 68,
            "margin": 32.76,
            "netMargin": 9.66,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 53,
            "name": "Office Equipment & Services",
            "numberOfFirms": 16,
            "margin": 32.45,
            "netMargin": 2.36,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 54,
            "name": "Oil/Gas (Integrated)",
            "numberOfFirms": 4,
            "margin": 36.54,
            "netMargin": 15.17,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 55,
            "name": "Oil/Gas (Production and Exploration)",
            "numberOfFirms": 174,
            "margin": 64.45,
            "netMargin": 26.01,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 56,
            "name": "Oil/Gas Distribution",
            "numberOfFirms": 23,
            "margin": 23.60,
            "netMargin": 2.08,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 57,
            "name": "Oilfield Svcs/Equip.",
            "numberOfFirms": 101,
            "margin": 11.83,
            "netMargin": 5.25,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 58,
            "name": "Packaging & Container",
            "numberOfFirms": 25,
            "margin": 21.33,
            "netMargin": 6.06,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 59,
            "name": "Paper/Forest Products",
            "numberOfFirms": 7,
            "margin": 29.64,
            "netMargin": 10.23,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 60,
            "name": "Power",
            "numberOfFirms": 48,
            "margin": 35.40,
            "netMargin": 9.17,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 61,
            "name": "Precious Metals",
            "numberOfFirms": 74,
            "margin": 36.98,
            "netMargin": 7.18,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 62,
            "name": "Publishing & Newspapers",
            "numberOfFirms": 20,
            "margin": 46.55,
            "netMargin": 2.82,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 63,
            "name": "R.E.I.T.",
            "numberOfFirms": 223,
            "margin": 60.46,
            "netMargin": 23.77,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 64,
            "name": "Real Estate (Development)",
            "numberOfFirms": 18,
            "margin": 32.51,
            "netMargin": 15.04,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 65,
            "name": "Real Estate (General/Diversified)",
            "numberOfFirms": 12,
            "margin": 48.08,
            "netMargin": 12.67,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 66,
            "name": "Real Estate (Operations & Services)",
            "numberOfFirms": 60,
            "margin": 31.13,
            "netMargin": -0.76,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 67,
            "name": "Recreation",
            "numberOfFirms": 57,
            "margin": 36.95,
            "netMargin": 1.30,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 68,
            "name": "Reinsurance",
            "numberOfFirms": 1,
            "margin": 10.64,
            "netMargin": 3.54,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 69,
            "name": "Restaurant/Dining",
            "numberOfFirms": 70,
            "margin": 30.07,
            "netMargin": 9.28,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 70,
            "name": "Retail (Automotive)",
            "numberOfFirms": 30,
            "margin": 21.04,
            "netMargin": 4.07,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 71,
            "name": "Retail (Building Supply)",
            "numberOfFirms": 15,
            "margin": 34.51,
            "netMargin": 8.67,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 72,
            "name": "Retail (Distributors)",
            "numberOfFirms": 69,
            "margin": 31.30,
            "netMargin": 7.30,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 73,
            "name": "Retail (General)",
            "numberOfFirms": 15,
            "margin": 23.25,
            "netMargin": 2.35,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 74,
            "name": "Retail (Grocery and Food)",
            "numberOfFirms": 13,
            "margin": 24.71,
            "netMargin": 1.96,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 75,
            "name": "Retail (Online)",
            "numberOfFirms": 63,
            "margin": 42.78,
            "netMargin": 0.64,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 76,
            "name": "Retail (Special Lines)",
            "numberOfFirms": 78,
            "margin": 29.90,
            "netMargin": 3.86,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 77,
            "name": "Rubber& Tires",
            "numberOfFirms": 3,
            "margin": 19.96,
            "netMargin": 4.21,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 78,
            "name": "Semiconductor",
            "numberOfFirms": 68,
            "margin": 54.23,
            "netMargin": 22.74,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 79,
            "name": "Semiconductor Equip",
            "numberOfFirms": 30,
            "margin": 44.65,
            "netMargin": 22.27,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 80,
            "name": "Shipbuilding & Marine",
            "numberOfFirms": 8,
            "margin": 36.12,
            "netMargin": 21.55,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 81,
            "name": "Shoe",
            "numberOfFirms": 13,
            "margin": 45.35,
            "netMargin": 11.17,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 82,
            "name": "Software (Entertainment)",
            "numberOfFirms": 91,
            "margin": 63.23,
            "netMargin": 20.91,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 83,
            "name": "Software (Internet)",
            "numberOfFirms": 33,
            "margin": 58.92,
            "netMargin": -19.07,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 84,
            "name": "Software (System & Application)",
            "numberOfFirms": 390,
            "margin": 70.92,
            "netMargin": 14.61,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 85,
            "name": "Steel",
            "numberOfFirms": 28,
            "margin": 26.23,
            "netMargin": 14.70,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 86,
            "name": "Telecom (Wireless)",
            "numberOfFirms": 16,
            "margin": 57.91,
            "netMargin": 2.54,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 87,
            "name": "Telecom. Equipment",
            "numberOfFirms": 79,
            "margin": 53.85,
            "netMargin": 13.29,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 88,
            "name": "Telecom. Services",
            "numberOfFirms": 49,
            "margin": 55.53,
            "netMargin": 12.81,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 89,
            "name": "Tobacco",
            "numberOfFirms": 15,
            "margin": 62.60,
            "netMargin": 23.46,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 90,
            "name": "Transportation",
            "numberOfFirms": 18,
            "margin": 21.94,
            "netMargin": 6.99,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 91,
            "name": "Transportation (Railroads)",
            "numberOfFirms": 4,
            "margin": 52.26,
            "netMargin": 27.65,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 92,
            "name": "Trucking",
            "numberOfFirms": 35,
            "margin": 27.26,
            "netMargin": 1.29,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 93,
            "name": "Utility (General)",
            "numberOfFirms": 15,
            "margin": 36.67,
            "netMargin": 12.68,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 94,
            "name": "Utility (Water)",
            "numberOfFirms": 16,
            "margin": 54.31,
            "netMargin": 25.12,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 95,
            "name": "Total Market",
            "numberOfFirms": 7165,
            "margin": 36.28,
            "netMargin": 8.89,
            "dataUpdated": "1/05/2023"
        },
        {
            "id": 96,
            "name": "Total Market (without financials)",
            "numberOfFirms": 5649,
            "margin": 33.19,
            "netMargin": 7.77,
            "dataUpdated": "1/05/2023"
        }
    ]
;
