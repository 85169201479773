import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { industryGrossMarginLookupArray, blobData } from './industryData.js';
import './App.css';
import ReactGA from 'react-ga';
import { Analytics } from '@vercel/analytics/react';
import {OpenAI} from 'openai'; // Import OpenAI
import Markdown from 'marked-react';
import Lottie from "lottie-react";
import loadingLottie from './loading-lottie.json';


ReactGA.initialize('G-ZPB926PQ7W');

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: '#999',
      },
    },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        color: '#999',
        callback: function (value) {
          return value + '%'; // Format y-axis ticks as percentages
        },
      },
    },
  },
  elements: {
    line: {
      tension: 0.3,
    },
    point: {
      radius: 0,
    },
  },
};

function App() {
  const [selectedIndustry, setSelectedIndustry] = useState('Advertising');
  const [totalSales, setTotalSales] = useState('0');
  const [shippingCost, setShippingCost] = useState('0');
  const [costOfGoods, setCostOfGoods] = useState('0');
  const [adCost, setAdCost] = useState('0');
  const [userGrossMargin, setUserGrossMargin] = useState(0); // State variable to store user's gross margin
  const [industryGrossMargin, setIndustryGrossMargin] = useState(0); // State variable to store selected industry's gross margin
  const [responseText, setResponseText] = useState('');
  const [responseStatus, setResponseStatus] = useState('Nothing');
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);

  ReactGA.pageview(window.location.pathname);

  const configuration = {
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  };

  const openai = new OpenAI(configuration);

  const handleCategoryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  const handleTotalSales = (event) => {
    setTotalSales(event.target.value);
  };

  const handleShippingCost = (event) => {
    setShippingCost(event.target.value);
  };

  const handleCostOfGoods = (event) => {
    setCostOfGoods(event.target.value);
  };

  const handleAdCost = (event) => {
    setAdCost(event.target.value);
  };

  useEffect(() => {
    // Find the selected industry's data from the industryGrossMarginLookupArray
    const selectedIndustryData = industryGrossMarginLookupArray.find(
      (industry) => industry.name === selectedIndustry
    );

    if (selectedIndustryData) {
      const industryMargin = selectedIndustryData.netMargin || 0;
      setIndustryGrossMargin(industryMargin);
    }
  }, [selectedIndustry]);

  useEffect(() => {
    // Calculate the user's gross margin based on user inputs
    const userMargin = (totalSales - shippingCost - costOfGoods - adCost) / totalSales;
    setUserGrossMargin(userMargin * 100);
  }, [totalSales, shippingCost, costOfGoods, adCost]);

const selectedIndustryData = industryGrossMarginLookupArray.find(
  (industry) => industry.name === selectedIndustry
);

// Calculate user's net profit as a percentage
const userNetProfitPercentage = (
  ((totalSales - costOfGoods - shippingCost - adCost) / totalSales) * 100
).toFixed(2);

// Calculate industry's net profit as a percentage
const industryNetProfitPercentage = (selectedIndustryData.netMargin).toFixed(2);

const data = {
  labels: ['User', 'Industry'],
  datasets: [
    {
      label: 'Net Margin',
      data: [userNetProfitPercentage, industryNetProfitPercentage],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const handleOpenAIChat = async () => {
  setIsLoadingResponse(true);
  setResponseStatus('Loading');
  console.log("handleOpenAIChat called"); // Add this line
  try {
    var contentText = "My industry is " + selectedIndustry + " my total sales is " + totalSales + " my shipping cost is "
    + shippingCost + " my cost of goods " + costOfGoods + " and my ad cost is " + adCost;
    var blobDataString = blobData.toString();
    const completion = await openai.chat.completions.create({
      messages: [
        { "role": "system", "content": blobDataString },
        { "role": "user", "content": "Based on my industry, give me a breakdown of how my business is doing and also give me more information on my industry and advice." },
        { "role": "user", "content": contentText }
      ],
      model: "gpt-4-1106-preview",
    });
    setResponseStatus('Nothing');
    setResponseText(completion.choices[0].message.content);
  } catch (error) {
    console.error("Error while making OpenAI API request:", error);
    setResponseStatus('Nothing');
  } finally {
    setIsLoadingResponse(false);
    setResponseStatus('Nothing');
  }
};


  return (
    <div className="App">
      <Analytics />
      <nav className="navbar">
        <div className="navbar-container">
          <a href="/" className="navbar-brand">Shopify Scan</a>
        </div>
      </nav>
            <div className="tutorial-cards">
              <div className="card">
                <h3>How to Use</h3>
                <p>Select an industry and enter your financial data to compare your net profit with the industry average.</p>
              </div>
              <div className="card">
                <h3>Select Industry</h3>
                <p>Choose an industry from the dropdown menu to see industry-specific data.</p>
              </div>
              <div className="card">
                <h3>Enter Financial Data</h3>
                <p>Fill in the input fields with your financial data: Total Sales, Shipping Cost, Cost of Goods, and Ad Cost.</p>
              </div>
              <div className="card">
                <h3>View Comparison</h3>
                <p>After entering your data, the chart will display a comparison of your net profit with the industry average.</p>
              </div>
            </div>
      <div className="controls">
        <div className="input-container">
          <label htmlFor="industrySelect">Select the industry:</label>
          <select id="industrySelect" value={selectedIndustry} onChange={handleCategoryChange}>
            <option value="0" disabled>Select the industry</option>
            {industryGrossMarginLookupArray.map((industry) => (
              <option key={industry.id} value={industry.name}>
                {industry.name}
              </option>
            ))}
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="totalSalesInput">Total Sales:</label>
          <input type="number" id="totalSalesInput" value={totalSales} onChange={handleTotalSales} placeholder="Total Sales" />
        </div>

        <div className="input-container">
          <label htmlFor="shippingCostInput">Shipping Cost:</label>
          <input type="number" id="shippingCostInput" value={shippingCost} onChange={handleShippingCost} placeholder="Shipping Cost" />
        </div>

        <div className="input-container">
          <label htmlFor="costOfGoodsInput">Cost of Goods:</label>
          <input type="number" id="costOfGoodsInput" value={costOfGoods} onChange={handleCostOfGoods} placeholder="Cost of Goods" />
        </div>

        <div className="input-container">
          <label htmlFor="adCostInput">Ad Cost:</label>
          <input type="number" id="adCostInput" value={adCost} onChange={handleAdCost} placeholder="Ad Cost" />
        </div>

        <button className="analyzeButton" onClick={handleOpenAIChat} disabled={isLoadingResponse}>Analyze</button>
      </div>

      <div className="chart-background">
        <div className="chart-container">
          <Bar data={data} options={options} />
        </div>
      </div>

      <div className="response-text report">
        <p>Shopify Scan Business Advisor Says:</p>
        {responseStatus === 'Loading' ? ( <div><p>Analyzing the business and comparing to industry data...</p> <Lottie className="lottie-file" animationData={loadingLottie} /></div>) : ( <Markdown>{responseText}</Markdown> )}
      </div>
    </div>
  );
}

export default App;
